import React, { useCallback, useEffect, useState } from "react";
import { PageProps } from "gatsby";

import { SEO } from "../components/common/seo";
import { AboutPage } from "../components/Layout/AboutPage";

const About: React.FC<PageProps> = () => {
  const [display, setDisplay] = useState(false);
  const [built, setBuilt] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    setDisplay(
      window.location.hostname.includes("netlify") ||
        window.location.hostname.includes("localhost")
    );
  }, []);

  const triggerBuild = useCallback(() => {
    setBuilt(true);
    const d = new Date();
    const time = `${d
      .getHours()
      .toString()
      .padStart(2, "0")}:${d.getMinutes().toString().padStart(2, "0")}`;

    fetch("https://api.netlify.com/build_hooks/5f86bd68d614ff89e6cc2c77", {
      method: "POST",
    })
      .then(() => {
        setMessage(`${time} – Successfully triggered build`);
      })
      .catch(() => {
        setMessage(`${time} – There was an error triggering the build`);
      });
  }, []);

  if (!display) {
    return null;
  }

  return (
    <>
      <SEO title="About" />

      {display && (
        <div style={{ marginTop: "50px", padding: "20px" }}>
          <p>{message}</p>
          <button onClick={triggerBuild} disabled={built}>
            Trigger build
          </button>
        </div>
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default About;
